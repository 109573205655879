<!--
 * @Author: 张博洋
 * @Date: 2021-08-15 10:45:09
 * @LastEditTime: 2022-01-20 11:07:04
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/doctor/detail.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="det">
    <div class="info mt8">
      <div class="doc-info">
        <div class="doc-img">
          <van-image width="100px"
                     height="118px"
                     fit="cover"
                     :src="d.avatar" />
        </div>
        <div class="doc-con ml18">
          <p class="name">{{d.doctorName}}</p>
          <p class="title mt4">{{d.technicalLevelName}}</p>
          <p class="hospital mt8">{{d.hospitalName}}</p>
          <p class="dep mt8">{{d.departmentName}}</p>
        </div>
      </div>
      <div class="desc mt24">
        <h1 class="mb12">医生简介</h1>

        <v-clamp v-if="text.length"
                 autoresize
                 location="end"
                 :max-lines="4"
                 :expanded="expanded"
                 @clampchange="clampchange">
          {{ text }}
          <span class="slotBtn"
                v-if="btnShow"
                slot="after"
                @click="toggle">
            {{ expanded?  '[收起]' :'[更多]' }}
          </span>
        </v-clamp>
        <!-- <v-clamp autoresize
                 location="end"
                 :expanded="false"
                 slot=""
                 :max-lines="3">{{ text }} <button slot="after"
                  slot-scope="{展开}">dsads</button></v-clamp> -->
        <!-- <TextOverflow class="textOverflow"
                      :text="text"
                      :width="400"
                      :maxLines="4">
          <template v-slot:default="{ clickToggle, expanded }">
            <button @click="clickToggle"
                    class="btn">
              {{ expanded ? "[收起]" : "[更多]" }}
            </button>
          </template>
        </TextOverflow> -->
      </div>
    </div>
    <div class="service mt8">
      <h1>服务内容</h1>
      <div class="item-service mt8"
           v-if="service.length">
        <div v-for="(item,index) in service"
             :key="index"
             class="item"
             :data-id="'service'+ item.businessType"
             @click="handlePop(item)">
          <img :class="item.businessType === 1 ? 'bg1' : 'bg2' "
               :src="item.businessType === 1 ? require('@/static/img/icon-service1.png') : require('@/static/img/icon-service2.png')"
               alt=""
               :data-id="'service'+ item.businessType" />
          <div class="ml16"
               :data-id="'service'+ item.businessType">
            <div class="mt2">
              <h2 :data-id="'service'+ item.businessType">{{item.title}}</h2>
              <p :data-id="'service'+ item.businessType"
                 :style="{color:item.businessType === 1 ? '#FF8200' : '#1AB393'}"
                 v-if="item.realPrice !== 0">¥ {{item.realPrice}}/{{item.serviceTime}}{{item.timeUnit === 1 ? '小时' : '分钟'}}</p>
              <p :data-id="'service'+ item.businessType"
                 v-else>免费服务</p>
            </div>
            <div class="brief mt2">{{item.brief}}</div>
          </div>

        </div>

      </div>
      <div class="empty mt8"
           v-else>
        <img src="@/static/img/empty.png"
             alt="">
        <p class="mt4">暂无服务</p>
      </div>

    </div>

  </div>
</template>

<script>
import VClamp from 'vue-clamp'
// import TextOverflow from '@/components/textOverflow/textOverflow.vue'
export default {
  data() {
    return {
      popShow: false,
      text: '',
      d: {},
      expanded: false, //收否展开
      service: [],
      c: false,
      btnShow: false,
      brief: '',
      businessType: '',
    }
  },
  components: {
    VClamp,
  },
  created() {
    this.getDoctorDetail()
    console.log(this.$route.query.doctorAccountId)
  },
  mounted() {
    console.log(document.body.clientWidth)
    this.maxWidth = document.body.clientWidth - 48
    this.imgWidth = Math.floor((document.body.clientWidth - 48 - 30) / 4)
  },
  methods: {
    handlePop(item) {
      this.popShow = true
      this.brief = item.brief
      this.businessType = item.businessType
    },
    getDoctorDetail() {
      this.$axios({
        url: 'doctor/getDoctorById',
        data: {
          id: this.$route.query.doctorAccountId,
        },
        elseData: {
          loading: true,
        },
        _self: this,
      }).then((res) => {
        if (res.d) {
          this.d = res.d
          this.service = res.d.service || []
          this.text = res.d.introduction || ''
          this.$.setTitle(this.d.doctorName + '医生')
          // this.text = 'aaa'
        } else {
          this.APPerror = true
          this.APPerrorMsg = '查询就诊人失败！'
        }
      })
    },
    popOver($event) {
      console.log($event)
      this.popShow = true
      if (!$event.target.dataset.id) {
        this.popShow = false
      }
    },
    toggle() {
      this.c = true
      this.expanded = !this.expanded
    },
    clampchange(expanded) {
      if (!expanded && !this.c) {
        this.btnShow = false
      } else {
        this.btnShow = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.det {
  overflow-y: auto;
  .info {
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 10px 12px;
    margin: 0 8px;
    .doc-info {
      display: flex;
      justify-content: flex-start;
      padding: 8px 0 17px;
      margin: 0 8px;
      border-bottom: 1px solid #f5f5f5;
      .doc-img {
        width: 100px;
        height: 118px;
        border-radius: 8px;
        overflow: hidden;
      }
      .doc-con {
        flex: 1;
        display: flex;
        flex-direction: column;
        .name {
          font-size: 20px;
          font-weight: 500;
          color: #333333;
        }
        .title {
          font-size: 14px;
          font-weight: 400;
          color: #ff8200;
        }
        .dep {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .desc {
      padding: 0 8px;
      h1 {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 13px;
      }
      & > h1::before {
        content: '';
        position: absolute;
        left: 0;
        width: 5px;
        height: 16px;
        background: #1ab370;
        border-radius: 3px;
      }
    }
  }
  .service {
    background: #ffffff;
    border-radius: 8px;
    padding: 10px 16px 22px;
    margin: 0 8px;

    h1 {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 49px;
      margin-left: 9;
    }
    .item-service {
      .item {
        border-radius: 8px;
        display: flex;
        flex: 1;
        margin-right: 11px;
        margin-bottom: 30px;
        img {
          width: 24px;
          height: 24px;
          padding: 12px;
          border-radius: 8px;
          box-sizing: content-box;
        }
        & > div {
          display: flex;
          flex-direction: column;
          & > div {
            display: flex;
            align-items: center;
            h2 {
              font-size: 14px;
              font-weight: 500;
              color: #333333;
            }
            p {
              margin-left: 8px;
              font-size: 11px;
              font-weight: 500;
              color: #999999;
            }
          }
          .brief {
            font-size: 11px;
            font-weight: 400;
            color: #999999;
            line-height: 15px;
            border-bottom: 1px solid #f5f5f5;
            padding-bottom: 20px;
          }
        }
      }

      .item:last-of-type {
        margin-bottom: 0;
        .brief {
          border-bottom: none;
        }
      }
      .bg2 {
        background: #1ab393;
      }
      .bg1 {
        background: #ff8200;
      }
    }
    .empty {
      height: 74px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f5f5f5;
      border-radius: 8px;
      img {
        width: 36px;
      }
    }
    .popOver {
      position: absolute;
      width: 100%;
      margin-top: 20px;
      border-radius: 8px;
      padding: 16px;
      top: 85px;
      left: 0;
      font-size: 14px;
      color: #999;
      white-space: pre-line;
    }
    .border1 {
      border: 1px solid #ff8200;
    }
    .border2 {
      border: 1px solid #1ab370;
    }
  }
}
.textOverflow {
  color: #666;
}
.slotBtn {
  color: color(primary);
}
</style>